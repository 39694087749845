import { image, location, locationDefault } from "@/store/model";
import { default_image } from "@/core/config/AppConfig";

export interface Agency {
  _id?: string;
  name: string;
  slug: string;
  phone: string;
  website?: string;
  address: string;
  content: string;
  images: image[];
  location: location;
}

export const AgencyDefault: Agency = {
  name: "",
  slug: "",
  phone: "",
  content: "",
  website: "",
  address: "",
  location: locationDefault,
  images: [],
};
